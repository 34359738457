import { useState, ReactElement, cloneElement, Children, isValidElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, FormProvider, UseFormReturn } from 'react-hook-form'

import { Submit } from './buttons'

type InputLineProps = {
  children: (({ readOnly }: { readOnly: boolean }) => ReactElement) | ReactElement | ReactElement[]
  label: string
  submit?: (data: any) => Promise<void>
  methods?: UseFormReturn<any>
}

const InputLine = (props: InputLineProps) => {
  return props.submit && props.methods
    ? <FormProvider {...props.methods}>
        <InputLineContent {...props} />
      </FormProvider>
    : <InputLineContent {...props} />
}

const InputLineContent = ({ children, label, submit, methods }: InputLineProps) => {
  const { t } = useTranslation()
  const { reset, handleSubmit, formState: { isDirty } } = useFormContext()

  const [readOnly, setReadOnly] = useState<boolean>(!!submit && !!methods)
  const toggleReadOnly = () => {
    reset()
    setReadOnly(prev => !prev)
  }

  return (
    <form onSubmit={submit
      ? handleSubmit((data: any) => submit(data).finally(() => setReadOnly(true)))
      : undefined}>
      <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-4 items-center">
        <dt className="block text-sm font-medium text-gray-700">{label}</dt>
          <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            <span className="w-full flex gap-2 flex-col sm:flex-row">
              {typeof children === 'function'
                ? children({ readOnly })
                : Children.map(children, (child: ReactElement<{readOnly: boolean}>) => isValidElement(child) ? cloneElement(child, { readOnly }) : child)}
            </span>
            {submit
              ? <span className="ml-4 flex flex-shrink-0 items-center space-x-4">
                  {readOnly
                    ? <button
                        type='button'
                        onClick={toggleReadOnly}
                        className="rounded-md bg-white font-medium text-primary-dark hover:text-primary focus:outline-none"
                      >
                        {t('global:actions.update')}
                      </button>
                    : <>
                        {isDirty
                          ? <Submit outlined={true}>{t('global:actions.save')}</Submit>
                          : <button
                              type='button'
                              onClick={toggleReadOnly}
                              className="rounded-md bg-white font-medium text-primary-dark hover:text-primary focus:outline-none"
                            >
                              {t('global:actions.save')}
                            </button>
                        }
                        <span className="text-gray-300" aria-hidden="true">|</span>
                        <button
                          type='button'
                          onClick={toggleReadOnly}
                          className="rounded-md bg-white font-medium text-primary-dark hover:text-primary focus:outline-none"
                        >
                          {t('global:actions.cancel')}
                        </button>
                      </>
                  }
                </span>
              : null
            }
          </dd>
      </div>
    </form>
  )
}

export default InputLine
