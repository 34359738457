import { twMerge } from 'tailwind-merge'

type BadgeProps = {
  children: string
  type?: 'INFO' | 'ERROR' | 'SUCCESS' | 'WARNING' | 'CUSTOM'
  className?: string
}

const Badge = ({ children, type = 'CUSTOM', className = '' }: BadgeProps) => {
  const color = {
    INFO: 'bg-blue-100',
    ERROR: 'bg-red-100',
    SUCCESS: 'bg-green-100',
    WARNING: 'bg-orange-100',
    CUSTOM: ''
  }

  return (
    <span className={twMerge('inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium', className, color[type])}>
      {children}
    </span>
  )
}

export default Badge
