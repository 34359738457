// import axios from 'axios'

import type { Offer } from 'types'

const getOffers = (t: any): Offer[] => {
  // const res = await axios.get('/tools/stripe/prices')

  return [
    {
      id: 'starter',
      type: 'STARTER',
      favorite: false,
      name: t('offers:starter.name'),
      prices: [
        {
          stripeId: 'no_stripe_id',
          currency: 'USD',
          price: 0,
          pricePerMonth: 0,
          interval: 'MONTH'
        },
        {
          stripeId: 'no_stripe_id',
          currency: 'USD',
          price: 0,
          pricePerMonth: 0,
          interval: 'YEAR'
        }
      ],
      features: [
        t('offers:features.integrations', { media: 2 }),
        t('offers:features.archiving')
      ]
    },
    {
      id: 'business',
      type: 'BUSINESS',
      favorite: true,
      name: t('offers:business.name'),
      prices: [
        {
          stripeId: process.env.REACT_APP_STRIPE_PRICE_BUSINESS_MONTH!,
          currency: 'USD',
          price: 42,
          pricePerMonth: 42,
          interval: 'MONTH'
        },
        {
          stripeId: process.env.REACT_APP_STRIPE_PRICE_BUSINESS_YEAR!,
          currency: 'USD',
          price: 420,
          pricePerMonth: 35,
          interval: 'YEAR'
        }
      ],
      features: [
        t('offers:features.integrations', { media: '10' }),
        t('offers:features.note'),
        t('offers:features.archiving'),
        t('offers:features.agents', { agent: '20' }),
        t('offers:features.assignment')
      ]
    },
    {
      id: 'enterprise',
      type: 'ENTERPRISE',
      favorite: false,
      name: t('offers:enterprise.name'),
      prices: [
        {
          stripeId: process.env.REACT_APP_STRIPE_PRICE_ENTERPRISE_MONTH!,
          currency: 'USD',
          price: 58,
          pricePerMonth: 58,
          interval: 'MONTH'
        },
        {
          stripeId: process.env.REACT_APP_STRIPE_PRICE_ENTERPRISE_YEAR!,
          currency: 'USD',
          price: 580,
          pricePerMonth: 48,
          interval: 'YEAR'
        }
      ],
      features: [
        t('offers:features.integrations', { media: '20' }),
        t('offers:features.note'),
        t('offers:features.archiving'),
        t('offers:features.agents', { agent: '200' }),
        t('offers:features.assignment')
        // t('offers:features.dashboard'),
        // t('offers:features.templates')
      ]
    }
  ]
  // .map(offer => {
  //   return ({
  //     ...offer,
  //     prices: offer.prices && res?.data?.data
  //       ? offer.prices.map(p => {
  //         const relatedPrice = res.data.data.find((r: any) => r.id === p)
  //         return {
  //           stripeId: relatedPrice.id,
  //           currency: relatedPrice.currency as Currencies,
  //           price: relatedPrice.price,
  //           pricePerMonth: relatedPrice.pricePerMonth,
  //           interval: relatedPrice.interval as Intervals
  //         }
  //       })
  //       : ['month', 'year'].map(i => ({
  //           currency: 'usd' as Currencies,
  //           price: 0,
  //           pricePerMonth: 0,
  //           interval: i as Intervals
  //         }))
  //   })
  // })
}

export { getOffers }
