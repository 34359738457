import { useTranslation } from 'react-i18next'

import { XCircleIcon } from '@heroicons/react/24/outline'

import Offers from './offers'

type OffersProps = {
  open: boolean
  onClose: () => void
}

const OffersPage = ({ open, onClose }: OffersProps) => {
  const { t } = useTranslation()

  return !open
    ? <></>
    : <div className='fixed p-12 top-0 left-0 bg-white w-full h-full flex justify-center border-4 border-primary rounded-xl'>
        <div className='flex flex-col gap-8 w-full max-w-7xl'>
          <XCircleIcon className='absolute right-5 top-4 cursor-pointer h-8 w-8 hover:text-primary transform hover:rotate-180 transition duration-500 ease-in-out' onClick={onClose} />
          <div className='flex items-center justify-center font-semibold text-2xl mb-3'>{t('offers:title')}</div>
          <Offers />
        </div>
      </div>
}

export default OffersPage
