import { useMemo } from 'react'
import { ArrowTopRightOnSquareIcon, ArrowUpTrayIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/solid'

import defaultUser from 'assets/images/default_user.png'
import defaultCompany from 'assets/images/default_company.png'
import defaultFile from 'assets/images/default_file.png'

// import { getFileExtension } from 'utils/files'
import { twMerge } from 'tailwind-merge'

import IconButton from './buttons/iconButton'
import Delete from './dialogs/delete'
import Edit from './dialogs/edit'

type FilePreviewProps = {
  file: FileList | string | null
  type?: 'user' | 'company' | 'machine' | 'vehicle' | 'file' | 'constructionSite',
  onClick?: () => void
  readOnly?: boolean
  className?: string
  iconHover?: {
    edit?: {
      url: 'constructionSites' | 'users' | 'vehicles' | 'companies' | 'invoices' | 'driverLicenses' | 'jobs' | 'invoiceLabels' | 'files'
      id: string
      confirmAction: (res: any) => void
      defaultValue: string
    }
    delete?: {
      url: 'constructionSites' | 'users' | 'vehicles' | 'companies' | 'invoices' | 'driverLicenses' | 'jobs' | 'invoiceLabels' | 'files'
      id: string
      confirmAction: (res: any) => void
    }
  }
  displayPlaceholderIfNoValue?: boolean
}

const FilePreview = ({ file, type = 'file', onClick, readOnly, className, iconHover, displayPlaceholderIfNoValue }: FilePreviewProps) => {
  const url = useMemo(() => {
    const defaultIcon = type === 'company'
      ? defaultCompany
      : type === 'user'
        ? defaultUser
        : defaultFile

    return typeof file === 'string'
      ? file
      : !file?.length
          ? defaultIcon
          : URL.createObjectURL(file[0])
  }, [file])

  const fileType = useMemo(() => {
    return typeof file === 'string'
      // ? ['png', 'jpg', 'jpeg', 'svg', 'webp', 'pjp', 'pjpeg', 'jfif', 'gif', 'apng', 'avif'].includes(getFileExtension(file))
      ? 'IMAGE'
    // : 'FILE'
      : !file?.length
          ? 'DEFAULT'
          : file[0].type.includes('image') ? 'IMAGE' : 'FILE'
  }, [file])

  return (
    <div className={twMerge('relative w-8 aspect-square flex items-center', type === 'user' ? 'rounded-full overflow-hidden' : '', className)}>
      {readOnly && fileType === 'DEFAULT' && !displayPlaceholderIfNoValue
        ? null
        : fileType === 'IMAGE' || fileType === 'DEFAULT'
          ? <img
              className="h-full w-full"
              src={url}
              alt='file'
            />
          : <iframe
              src={url}
              className="h-full w-full"
            >
              <p>preview are not supported by your browser.</p>
            </iframe>
      }
      {readOnly && fileType === 'DEFAULT'
        ? null
        : iconHover
          ? <div className='h-full w-full absolute hover:bg-gray-50 opacity-50 top-0 left-0 text-transparent hover:text-black flex items-center justify-evenly cursor-pointer'>
              <IconButton Icon={ArrowTopRightOnSquareIcon} className="h-6 w-6" to={url} external />
              {iconHover.edit ? <Edit url={iconHover.edit.url} id={iconHover.edit.id} confirmAction={iconHover.edit.confirmAction} defaultValue={iconHover.edit.defaultValue} fullyCustom><IconButton Icon={PencilSquareIcon} className="h-6 w-6" /></Edit> : null}
              {iconHover.delete ? <Delete url={iconHover.delete.url} id={iconHover.delete.id} confirmAction={iconHover.delete.confirmAction} fullyCustom><IconButton Icon={TrashIcon} className="h-6 w-6" /></Delete> : null}
            </div>
          : <a target="_blank" href={fileType === 'DEFAULT' ? undefined : url} rel="noreferrer" onClick={fileType === 'DEFAULT' ? onClick : undefined}>
              <div className="h-full w-full absolute top-0 left-0 hover:bg-gray-50 opacity-50 text-transparent hover:text-black flex items-center justify-center cursor-pointer">
                {fileType === 'DEFAULT'
                  ? <ArrowUpTrayIcon className="h-4 w-4" />
                  : <ArrowTopRightOnSquareIcon className="h-4 w-4" />
                }
              </div>
            </a>
      }
    </div>
  )
}

export default FilePreview
