import { ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

type ButtonProps = {
  children: ReactElement | ReactElement[] | string
  onClick: () => void
  disabled?: boolean
  className?: string
  outlined?: boolean
}

const Button = ({ children, onClick, className, outlined, disabled }: ButtonProps) => {
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={onClick}
      className={twMerge('select-none inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-primary-dark focus:ring-offset-2',
        outlined || disabled
          ? 'flex justify-center rounded-md bg-white font-medium text-primary hover:text-primary-dark focus:outline-none border border-primary hover:border-primary-dark'
          : 'flex justify-center rounded-md border text-sm font-medium shadow-sm bg-primary text-white border-transparent hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary-light focus:ring-offset-2',
        disabled
          ? 'border-gray-400 hover:border-gray-400 text-gray-400 hover:text-gray-400 bg-gray-100'
          : '',
        className
      )}
    >
      {children}
    </button>
  )
}

export default Button
