import { ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'

import { ExclamationCircleIcon } from '@heroicons/react/20/solid'

import { twMerge } from 'tailwind-merge'

type TextAreaProps = {
  id: string
  label?: string
  info?: string
  disabled?: boolean
  readOnly?: boolean
  rows?: number
  placeholder?: string
  className?: string
  readOnlyElement?: string | ReactElement,
  resize?: boolean
}

const TextArea = ({ id, label, rows = 1, info, readOnly = false, disabled = false, placeholder, className, readOnlyElement, resize }: TextAreaProps) => {
  const { register, formState: { errors } } = useFormContext()

  return (
    <div className="w-full relative">
      {label
        ? <label htmlFor={id} className="block text-sm font-medium text-gray-700 mb-1">
            {label}
          </label>
        : null
      }

      <div className="relative w-full">
        {(readOnly || disabled) && readOnlyElement
          ? <span className='pl-3 h-9 flex items-center'>{readOnlyElement}</span>
          : <textarea
              {...register(id)}
              rows={rows}
              className={twMerge('block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary-light focus:outline-none focus:ring-primary-light sm:text-sm', resize ? '' : 'resize-none', className)}
              readOnly={readOnly || disabled}
              placeholder={placeholder}
            />
        }

        {(errors as any)?.[id]
          ? <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
            </div>
          : null
        }
      </div>

      {(errors as any)?.[id]
        ? <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
            {(errors as any)?.[id]?.message}
          </p>
        : info
          ? <p className="mt-1 text-xs text-gray-500" id={`${id}-info`}>
              {info}
            </p>
          : null
      }
    </div>
  )
}

export default TextArea
