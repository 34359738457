import { m } from 'framer-motion'

import { Logo } from 'components'

const LoadingScreen = () => {
  return (
    <div className="flex w-full h-full justify-center items-center">
      <div className="h-18">
        <m.div
          animate={{ y: ['0rem', '-5rem'] }}
          transition={{
            duration: 0.8,
            repeatType: 'loop',
            ease: 'easeOut'
          }}
        >
          <Logo type='SQUARE' to={null} className='h-18' />
        </m.div>
      </div>
    </div>
  )
}

export default LoadingScreen
