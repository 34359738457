import { twMerge } from 'tailwind-merge'

type AvatarProps = {
  src?: string
  alt?: string
  className?: string
}

const Avatar = ({ src, alt, className = '' }: AvatarProps) => {
  return src
    ? <div>
        <img
          className={twMerge('w-8 rounded-full', className, 'h-auto object-cover aspect-square')}
          src={src}
          loading="lazy"
          alt={alt}
        />
      </div>
    : <div
        className={twMerge('w-8 rounded-full text-xs bg-slate-300 flex items-center justify-center', className, 'object-cover aspect-square')}
      >
        {alt?.length ? alt[0].toUpperCase() : 'B'}
      </div>
}

export default Avatar
