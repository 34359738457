import { createContext, useState, useContext, useEffect, ReactNode, useMemo } from 'react'

import axios from 'axios'

import { useUser } from './user'

import { Workspace } from 'types'
import { OffersPage } from 'components'
import { Integration } from 'types/integration'

type WorkspaceProviderProps = {
  children: ReactNode
}

type InitialValues = {
  loading: boolean
  workspaces: Workspace[]
  integrations: Integration[]
  addIntegration: (newIntegration: Integration) => void
  updateIntegration: (integration: Integration) => void
  deleteIntegration: (integrationId: string) => void
  selectedWorkspace?: Workspace
  addWorkspace: (newWorkspace: Workspace) => void
  updateWorkspace: (updatedWorkspace: Workspace) => void
  selectWorkspace: (workspaceId: string) => Workspace
  openOffers: () => void
  integrationsActive: number
  integrationsLimit: number
}

const initialValues: InitialValues = {
  loading: true,
  workspaces: [],
  integrations: [],
  addIntegration: (newIntegration: Integration) => {},
  updateIntegration: (integration: Integration) => {},
  deleteIntegration: (integrationId: string) => {},
  selectedWorkspace: undefined,
  addWorkspace: (newWorkspace: Workspace) => {},
  updateWorkspace: (updatedWorkspace: Workspace) => {},
  selectWorkspace: (workspaceId: string) => ({} as any),
  openOffers: () => {},
  integrationsActive: 0,
  integrationsLimit: 2
}

const WorkspaceContext = createContext(initialValues)

const planToLimit = {
  STARTER: 2,
  BUSINESS: 10,
  ENTERPRISE: 20
}

const WorkspaceProvider = ({ children }: WorkspaceProviderProps) => {
  const { user, loading: loadingUser } = useUser()
  const [loading, setLoading] = useState<boolean>(true)
  const [workspaces, setWorkspaces] = useState<Workspace[]>([])
  const [integrations, setIntegrations] = useState<Integration[]>([])
  const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace | undefined>(undefined)

  const addIntegration = (newIntegration: Integration) => {
    setIntegrations(prevIntegrations => [...prevIntegrations.filter(integration => integration.media !== newIntegration.media), newIntegration])
  }

  const updateIntegration = (updatedIntegration: Integration) => {
    setIntegrations(prevIntegrations => [...prevIntegrations.filter(integration => integration.id !== updatedIntegration.id), updatedIntegration])
  }

  const deleteIntegration = (integrationId: string) => {
    setIntegrations(prevIntegrations => [...prevIntegrations.filter(integration => integration.id !== integrationId)])
  }

  const addWorkspace = (newWorkspace: Workspace) => {
    if (!workspaces.map(p => p.id).includes(newWorkspace.id)) {
      setWorkspaces(prevWorkspaces => [newWorkspace, ...prevWorkspaces])
      setSelectedWorkspace(newWorkspace)
    }
  }

  const updateWorkspace = (updatedWorkspace: Workspace) => {
    if (selectedWorkspace?.id === updatedWorkspace.id) {
      setSelectedWorkspace(prev => ({
        ...prev,
        ...updatedWorkspace
      }))
    }
    setWorkspaces(prevWorkspaces => prevWorkspaces.map(p => p.id === updatedWorkspace.id
      ? {
          ...p,
          ...updatedWorkspace
        }
      : p
    ))
  }

  const selectWorkspace = (id: string): Workspace => {
    if (selectedWorkspace?.id === id) return selectedWorkspace
    const fWorkspaces = workspaces.find((p: Workspace) => p.id === id)
    if (fWorkspaces) {
      setSelectedWorkspace(fWorkspaces)
      localStorage.setItem('bechatSelectedWorkspace', fWorkspaces.id)
      return fWorkspaces
    }
    throw new Error('')
  }

  useEffect(() => {
    const getWorkpaces = async () => {
      const resWorkspace: any = await axios.get('/workspaces')
      setWorkspaces(resWorkspace.data.data)
      const storedWorkspace = localStorage.getItem('bechatSelectedWorkspace') ? resWorkspace.data.data.find((rw: any) => rw.id === localStorage.getItem('bechatSelectedWorkspace')) : undefined
      const newSelectedWorkspace = storedWorkspace || resWorkspace.data.data[0]
      setSelectedWorkspace(newSelectedWorkspace)
      setLoading(false)
    }

    if (user) getWorkpaces()
    else if (!user && !loadingUser) {
      setLoading(false)
      setWorkspaces([])
      setIntegrations([])
      setSelectedWorkspace(undefined)
    }
  }, [user, loadingUser])

  useEffect(() => {
    const getIntegrations = async () => {
      const resIntegrations: any = await axios.get(`/workspaces/${selectedWorkspace!.id}/integrations`)
      setIntegrations(resIntegrations.data.data)
    }

    if (selectedWorkspace) getIntegrations()
  }, [selectedWorkspace])

  const [openOffers, setOpenOffers] = useState<boolean>(false)
  useEffect(() => {
    setOpenOffers(false)
  }, [user, selectedWorkspace])

  const integrationsActive: number = useMemo(() => integrations.filter(integration => integration.status === 'CONNECTED').length, [integrations])
  const integrationsLimit: number = useMemo(() => planToLimit[selectedWorkspace?.plan?.type || 'STARTER'], [planToLimit, selectedWorkspace])

  return (
    <WorkspaceContext.Provider value={{
      loading,
      workspaces,
      integrations,
      addIntegration,
      updateIntegration,
      deleteIntegration,
      selectedWorkspace,
      addWorkspace,
      updateWorkspace,
      selectWorkspace,
      openOffers: () => setOpenOffers(true),
      integrationsActive,
      integrationsLimit
    }}>
      {children}
      <OffersPage open={!!user && !!selectedWorkspace && openOffers} onClose={() => setOpenOffers(false)} />
    </WorkspaceContext.Provider>
  )
}

const useWorkspace = () => {
  return useContext(WorkspaceContext)
}

export { WorkspaceProvider, useWorkspace }
