import { /* StrictMode, */ Suspense, lazy } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'

import { LazyMotion, domMax } from 'framer-motion'

import './i18n'

import reportWebVitals from './reportWebVitals'

import { LoadingScreen } from 'components'
import ContextsProvider from 'contexts'

import 'react-tooltip/dist/react-tooltip.css'
import './assets/styles/index.css'

import ReactGA from 'react-ga4'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

if (process.env.REACT_APP_GA4_ID) {
  ReactGA.initialize(process.env.REACT_APP_GA4_ID)
}

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0
  })
}

if (process.env.REACT_APP_META_APP_ID && process.env.REACT_APP_META_VERSION) {
  const script = document.createElement('script')
  script.textContent = `
    window.fbAsyncInit = function () {
      FB.init({
        appId: '${process.env.REACT_APP_META_APP_ID}',
        cookie: true,
        xfbml: true,
        version: '${process.env.REACT_APP_META_VERSION}'
      })
    
      FB.AppEvents.logPageView()
    };
    
    (function (d, s, id) {
      const fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      const js = d.createElement(s); js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    }(document, 'script', 'facebook-jssdk'))
  `
  script.async = true
  document.body.appendChild(script)
}

const LazyApp = lazy(() => import('./App'))

const container = document.getElementById('root')

const root = createRoot(container!)

root.render(
  // <StrictMode>
    <ContextsProvider>
      <HelmetProvider>
        <LazyMotion strict features={domMax}>
          <Suspense fallback={<LoadingScreen />}>
            <BrowserRouter>
              <LazyApp />
            </BrowserRouter>
          </Suspense>
        </LazyMotion>
      </HelmetProvider>
    </ContextsProvider>
  // </StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
