import i18next from 'i18next'

import type { Option } from 'types'

export type Country = {
  name: string
  phoneCode: string
  code: string
  flag: string
  language?: string
}

const countries: Country[] = [
  { name: 'Afghanistan', phoneCode: '+93', code: 'AF', flag: '🇦🇫' },
  { name: 'Albania', phoneCode: '+355', code: 'AL', flag: '🇦🇱' },
  { name: 'Algeria', phoneCode: '+213', code: 'DZ', flag: '🇩🇿' },
  { name: 'AmericanSamoa', phoneCode: '+1684', code: 'AS', flag: '🇦🇸' },
  { name: 'Andorra', phoneCode: '+376', code: 'AD', flag: '🇦🇩' },
  { name: 'Angola', phoneCode: '+244', code: 'AO', flag: '🇦🇴' },
  { name: 'Anguilla', phoneCode: '+1264', code: 'AI', flag: '🇦🇮' },
  { name: 'Antarctica', phoneCode: '+672', code: 'AQ', flag: '🇦🇶' },
  { name: 'Antigua and Barbuda', phoneCode: '+1268', code: 'AG', flag: '🇦🇬' },
  { name: 'Argentina', phoneCode: '+54', code: 'AR', flag: '🇦🇷' },
  { name: 'Armenia', phoneCode: '+374', code: 'AM', flag: '🇦🇲' },
  { name: 'Aruba', phoneCode: '+297', code: 'AW', flag: '🇦🇼' },
  { name: 'Australia', phoneCode: '+61', code: 'AU', flag: '🇦🇺' },
  { name: 'Austria', phoneCode: '+43', code: 'AT', flag: '🇦🇹' },
  { name: 'Azerbaijan', phoneCode: '+994', code: 'AZ', flag: '🇦🇿' },
  { name: 'Bahamas', phoneCode: '+1242', code: 'BS', flag: '🇧🇸' },
  { name: 'Bahrain', phoneCode: '+973', code: 'BH', flag: '🇧🇭' },
  { name: 'Bangladesh', phoneCode: '+880', code: 'BD', flag: '🇧🇩' },
  { name: 'Barbados', phoneCode: '+1246', code: 'BB', flag: '🇧🇧' },
  { name: 'Belarus', phoneCode: '+375', code: 'BY', flag: '🇧🇾' },
  { name: 'Belgium', phoneCode: '+32', code: 'BE', flag: '🇧🇪' },
  { name: 'Belize', phoneCode: '+501', code: 'BZ', flag: '🇧🇿' },
  { name: 'Benin', phoneCode: '+229', code: 'BJ', flag: '🇧🇯' },
  { name: 'Bermuda', phoneCode: '+1441', code: 'BM', flag: '🇧🇲' },
  { name: 'Bhutan', phoneCode: '+975', code: 'BT', flag: '🇧🇹' },
  { name: 'Bolivia, Plurinational State of', phoneCode: '+591', code: 'BO', flag: '🇧🇴' },
  { name: 'Bosnia and Herzegovina', phoneCode: '+387', code: 'BA', flag: '🇧🇦' },
  { name: 'Botswana', phoneCode: '+267', code: 'BW', flag: '🇧🇼' },
  { name: 'Brazil', phoneCode: '+55', code: 'BR', flag: '🇧🇷' },
  { name: 'British Indian Ocean Territory', phoneCode: '+246', code: 'IO', flag: '🇮🇴' },
  { name: 'Brunei Darussalam', phoneCode: '+673', code: 'BN', flag: '🇧🇳' },
  { name: 'Bulgaria', phoneCode: '+359', code: 'BG', flag: '🇧🇬' },
  { name: 'Burkina Faso', phoneCode: '+226', code: 'BF', flag: '🇧🇫' },
  { name: 'Burundi', phoneCode: '+257', code: 'BI', flag: '🇧🇮' },
  { name: 'Cambodia', phoneCode: '+855', code: 'KH', flag: '🇰🇭' },
  { name: 'Cameroon', phoneCode: '+237', code: 'CM', flag: '🇨🇲' },
  { name: 'Canada', phoneCode: '+1', code: 'CA', flag: '🇨🇦' },
  { name: 'Cape Verde', phoneCode: '+238', code: 'CV', flag: '🇨🇻' },
  { name: 'Cayman Islands', phoneCode: '+345', code: 'KY', flag: '🇰🇾' },
  { name: 'Central African Republic', phoneCode: '+236', code: 'CF', flag: '🇨🇫' },
  { name: 'Chad', phoneCode: '+235', code: 'TD', flag: '🇹🇩' },
  { name: 'Chile', phoneCode: '+56', code: 'CL', flag: '🇨🇱' },
  { name: 'China', phoneCode: '+86', code: 'CN', flag: '🇨🇳' },
  { name: 'Christmas Island', phoneCode: '+61', code: 'CX', flag: '🇨🇽' },
  { name: 'Cocos (Keeling) Islands', phoneCode: '+61', code: 'CC', flag: '🇨🇨' },
  { name: 'Colombia', phoneCode: '+57', code: 'CO', flag: '🇨🇴' },
  { name: 'Comoros', phoneCode: '+269', code: 'KM', flag: '🇰🇲' },
  { name: 'Congo', phoneCode: '+242', code: 'CG', flag: '🇨🇬' },
  { name: 'Congo, The Democratic Republic of the', phoneCode: '+243', code: 'CD', flag: '🇨🇩' },
  { name: 'Cook Islands', phoneCode: '+682', code: 'CK', flag: '🇨🇰' },
  { name: 'Costa Rica', phoneCode: '+506', code: 'CR', flag: '🇨🇷' },
  { name: "Cote d'Ivoire", phoneCode: '+225', code: 'CI', flag: '🇨🇮' },
  { name: 'Croatia', phoneCode: '+385', code: 'HR', flag: '🇭🇷' },
  { name: 'Cuba', phoneCode: '+53', code: 'CU', flag: '🇨🇺' },
  { name: 'Cyprus', phoneCode: '+357', code: 'CY', flag: '🇨🇾' },
  { name: 'Czech Republic', phoneCode: '+420', code: 'CZ', flag: '🇨🇿' },
  { name: 'Denmark', phoneCode: '+45', code: 'DK', flag: '🇩🇰' },
  { name: 'Djibouti', phoneCode: '+253', code: 'DJ', flag: '🇩🇯' },
  { name: 'Dominica', phoneCode: '+1767', code: 'DM', flag: '🇩🇲' },
  { name: 'Dominican Republic', phoneCode: '+1849', code: 'DO', flag: '🇩🇴' },
  { name: 'Ecuador', phoneCode: '+593', code: 'EC', flag: '🇪🇨' },
  { name: 'Egypt', phoneCode: '+20', code: 'EG', flag: '🇪🇬' },
  { name: 'El Salvador', phoneCode: '+503', code: 'SV', flag: '🇸🇻' },
  { name: 'Equatorial Guinea', phoneCode: '+240', code: 'GQ', flag: '🇬🇶' },
  { name: 'Eritrea', phoneCode: '+291', code: 'ER', flag: '🇪🇷' },
  { name: 'Estonia', phoneCode: '+372', code: 'EE', flag: '🇪🇪' },
  { name: 'Ethiopia', phoneCode: '+251', code: 'ET', flag: '🇪🇹' },
  { name: 'Falkland Islands (Malvinas)', phoneCode: '+500', code: 'FK', flag: '🇫🇰' },
  { name: 'Faroe Islands', phoneCode: '+298', code: 'FO', flag: '🇫🇴' },
  { name: 'Fiji', phoneCode: '+679', code: 'FJ', flag: '🇫🇯' },
  { name: 'Finland', phoneCode: '+358', code: 'FI', flag: '🇫🇮' },
  { name: 'France', phoneCode: '+33', code: 'FR', language: 'FR', flag: '🇫🇷' },
  { name: 'French Guiana', phoneCode: '+594', code: 'GF', flag: '🇬🇫' },
  { name: 'French Polynesia', phoneCode: '+689', code: 'PF', flag: '🇵🇫' },
  { name: 'Gabon', phoneCode: '+241', code: 'GA', flag: '🇬🇦' },
  { name: 'Gambia', phoneCode: '+220', code: 'GM', flag: '🇬🇲' },
  { name: 'Georgia', phoneCode: '+995', code: 'GE', flag: '🇬🇪' },
  { name: 'Germany', phoneCode: '+49', code: 'DE', flag: '🇩🇪' },
  { name: 'Ghana', phoneCode: '+233', code: 'GH', flag: '🇬🇭' },
  { name: 'Gibraltar', phoneCode: '+350', code: 'GI', flag: '🇬🇮' },
  { name: 'Greece', phoneCode: '+30', code: 'GR', flag: '🇬🇷' },
  { name: 'Greenland', phoneCode: '+299', code: 'GL', flag: '🇬🇱' },
  { name: 'Grenada', phoneCode: '+1473', code: 'GD', flag: '🇬🇩' },
  { name: 'Guadeloupe', phoneCode: '+590', code: 'GP', flag: '🇬🇵' },
  { name: 'Guam', phoneCode: '+1671', code: 'GU', flag: '🇬🇺' },
  { name: 'Guatemala', phoneCode: '+502', code: 'GT', flag: '🇬🇹' },
  { name: 'Guernsey', phoneCode: '+44', code: 'GG', flag: '🇬🇬' },
  { name: 'Guinea', phoneCode: '+224', code: 'GN', flag: '🇬🇳' },
  { name: 'Guinea-Bissau', phoneCode: '+245', code: 'GW', flag: '🇬🇼' },
  { name: 'Guyana', phoneCode: '+592', code: 'GY', flag: '🇬🇾' },
  { name: 'Haiti', phoneCode: '+509', code: 'HT', flag: '🇭🇹' },
  { name: 'Holy See (Vatican City State)', phoneCode: '+379', code: 'VA', flag: '🇻🇦' },
  { name: 'Honduras', phoneCode: '+504', code: 'HN', flag: '🇭🇳' },
  { name: 'Hong Kong', phoneCode: '+852', code: 'HK', flag: '🇭🇰' },
  { name: 'Hungary', phoneCode: '+36', code: 'HU', flag: '🇭🇺' },
  { name: 'Iceland', phoneCode: '+354', code: 'IS', flag: '🇮🇸' },
  { name: 'India', phoneCode: '+91', code: 'IN', flag: '🇮🇳' },
  { name: 'Indonesia', phoneCode: '+62', code: 'ID', flag: '🇮🇩' },
  { name: 'Iran, Islamic Republic of', phoneCode: '+98', code: 'IR', flag: '🇮🇷' },
  { name: 'Iraq', phoneCode: '+964', code: 'IQ', flag: '🇮🇶' },
  { name: 'Ireland', phoneCode: '+353', code: 'IE', flag: '🇮🇪' },
  { name: 'Isle of Man', phoneCode: '+44', code: 'IM', flag: '🇮🇲' },
  { name: 'Israel', phoneCode: '+972', code: 'IL', flag: '🇮🇱' },
  { name: 'Italy', phoneCode: '+39', code: 'IT', flag: '🇮🇹' },
  { name: 'Jamaica', phoneCode: '+1876', code: 'JM', flag: '🇯🇲' },
  { name: 'Japan', phoneCode: '+81', code: 'JP', flag: '🇯🇵' },
  { name: 'Jersey', phoneCode: '+44', code: 'JE', flag: '🇯🇪' },
  { name: 'Jordan', phoneCode: '+962', code: 'JO', flag: '🇯🇴' },
  { name: 'Kazakhstan', phoneCode: '+77', code: 'KZ', flag: '🇰🇿' },
  { name: 'Kenya', phoneCode: '+254', code: 'KE', flag: '🇰🇪' },
  { name: 'Kiribati', phoneCode: '+686', code: 'KI', flag: '🇰🇮' },
  { name: "Korea, Democratic People's Republic of", phoneCode: '+850', code: 'KP', flag: '🇰🇵' },
  { name: 'Korea, Republic of', phoneCode: '+82', code: 'KR', flag: '🇰🇷' },
  { name: 'Kuwait', phoneCode: '+965', code: 'KW', flag: '🇰🇼' },
  { name: 'Kyrgyzstan', phoneCode: '+996', code: 'KG', flag: '🇰🇬' },
  { name: "Lao People's Democratic Republic", phoneCode: '+856', code: 'LA', flag: '🇱🇦' },
  { name: 'Latvia', phoneCode: '+371', code: 'LV', flag: '🇱🇻' },
  { name: 'Lebanon', phoneCode: '+961', code: 'LB', flag: '🇱🇧' },
  { name: 'Lesotho', phoneCode: '+266', code: 'LS', flag: '🇱🇸' },
  { name: 'Liberia', phoneCode: '+231', code: 'LR', flag: '🇱🇷' },
  { name: 'Libyan Arab Jamahiriya', phoneCode: '+218', code: 'LY', flag: '🇱🇾' },
  { name: 'Liechtenstein', phoneCode: '+423', code: 'LI', flag: '🇱🇮' },
  { name: 'Lithuania', phoneCode: '+370', code: 'LT', flag: '🇱🇹' },
  { name: 'Luxembourg', phoneCode: '+352', code: 'LU', flag: '🇱🇺' },
  { name: 'Macao', phoneCode: '+853', code: 'MO', flag: '🇲🇴' },
  { name: 'Macedonia, The Former Yugoslav Republic of', phoneCode: '+389', code: 'MK', flag: '🇲🇰' },
  { name: 'Madagascar', phoneCode: '+261', code: 'MG', flag: '🇲🇬' },
  { name: 'Malawi', phoneCode: '+265', code: 'MW', flag: '🇲🇼' },
  { name: 'Malaysia', phoneCode: '+60', code: 'MY', flag: '🇲🇾' },
  { name: 'Maldives', phoneCode: '+960', code: 'MV', flag: '🇲🇻' },
  { name: 'Mali', phoneCode: '+223', code: 'ML', flag: '🇲🇱' },
  { name: 'Malta', phoneCode: '+356', code: 'MT', flag: '🇲🇹' },
  { name: 'Marshall Islands', phoneCode: '+692', code: 'MH', flag: '🇲🇭' },
  { name: 'Martinique', phoneCode: '+596', code: 'MQ', flag: '🇲🇶' },
  { name: 'Mauritania', phoneCode: '+222', code: 'MR', flag: '🇲🇷' },
  { name: 'Mauritius', phoneCode: '+230', code: 'MU', flag: '🇲🇺' },
  { name: 'Mayotte', phoneCode: '+262', code: 'YT', flag: '🇾🇹' },
  { name: 'Mexico', phoneCode: '+52', code: 'MX', flag: '🇲🇽' },
  { name: 'Micronesia, Federated States of', phoneCode: '+691', code: 'FM', flag: '🇫🇲' },
  { name: 'Moldova, Republic of', phoneCode: '+373', code: 'MD', flag: '🇲🇩' },
  { name: 'Monaco', phoneCode: '+377', code: 'MC', flag: '🇲🇨' },
  { name: 'Mongolia', phoneCode: '+976', code: 'MN', flag: '🇲🇳' },
  { name: 'Montenegro', phoneCode: '+382', code: 'ME', flag: '🇲🇪' },
  { name: 'Montserrat', phoneCode: '+1664', code: 'MS', flag: '🇲🇸' },
  { name: 'Morocco', phoneCode: '+212', code: 'MA', flag: '🇲🇦' },
  { name: 'Mozambique', phoneCode: '+258', code: 'MZ', flag: '🇲🇿' },
  { name: 'Myanmar', phoneCode: '+95', code: 'MM', flag: '🇲🇲' },
  { name: 'Namibia', phoneCode: '+264', code: 'NA', flag: '🇳🇦' },
  { name: 'Nauru', phoneCode: '+674', code: 'NR', flag: '🇳🇷' },
  { name: 'Nepal', phoneCode: '+977', code: 'NP', flag: '🇳🇵' },
  { name: 'Netherlands', phoneCode: '+31', code: 'NL', flag: '🇳🇱' },
  { name: 'Netherlands Antilles', phoneCode: '+599', code: 'AN', flag: '🇦🇳' },
  { name: 'New Caledonia', phoneCode: '+687', code: 'NC', flag: '🇳🇨' },
  { name: 'New Zealand', phoneCode: '+64', code: 'NZ', flag: '🇳🇿' },
  { name: 'Nicaragua', phoneCode: '+505', code: 'NI', flag: '🇳🇮' },
  { name: 'Niger', phoneCode: '+227', code: 'NE', flag: '🇳🇪' },
  { name: 'Nigeria', phoneCode: '+234', code: 'NG', flag: '🇳🇬' },
  { name: 'Niue', phoneCode: '+683', code: 'NU', flag: '🇳🇺' },
  { name: 'Norfolk Island', phoneCode: '+672', code: 'NF', flag: '🇳🇫' },
  { name: 'Northern Mariana Islands', phoneCode: '+1670', code: 'MP', flag: '🇲🇵' },
  { name: 'Norway', phoneCode: '+47', code: 'NO', flag: '🇳🇴' },
  { name: 'Oman', phoneCode: '+968', code: 'OM', flag: '🇴🇲' },
  { name: 'Pakistan', phoneCode: '+92', code: 'PK', flag: '🇵🇰' },
  { name: 'Palau', phoneCode: '+680', code: 'PW', flag: '🇵🇼' },
  { name: 'Palestinian Territory, Occupied', phoneCode: '+970', code: 'PS', flag: '🇵🇸' },
  { name: 'Panama', phoneCode: '+507', code: 'PA', flag: '🇵🇦' },
  { name: 'Papua New Guinea', phoneCode: '+675', code: 'PG', flag: '🇵🇬' },
  { name: 'Paraguay', phoneCode: '+595', code: 'PY', flag: '🇵🇾' },
  { name: 'Peru', phoneCode: '+51', code: 'PE', flag: '🇵🇪' },
  { name: 'Philippines', phoneCode: '+63', code: 'PH', flag: '🇵🇭' },
  { name: 'Pitcairn', phoneCode: '+872', code: 'PN', flag: '🇵🇳' },
  { name: 'Poland', phoneCode: '+48', code: 'PL', flag: '🇵🇱' },
  { name: 'Portugal', phoneCode: '+351', code: 'PT', flag: '🇵🇹' },
  { name: 'Puerto Rico', phoneCode: '+1939', code: 'PR', flag: '🇵🇷' },
  { name: 'Qatar', phoneCode: '+974', code: 'QA', flag: '🇶🇦' },
  { name: 'Romania', phoneCode: '+40', code: 'RO', flag: '🇷🇴' },
  { name: 'Russia', phoneCode: '+7', code: 'RU', flag: '🇷🇺' },
  { name: 'Rwanda', phoneCode: '+250', code: 'RW', flag: '🇷🇼' },
  { name: 'Réunion', phoneCode: '+262', code: 'RE', flag: '🇷🇪' },
  { name: 'Saint Barthélemy', phoneCode: '+590', code: 'BL', flag: '🇧🇱' },
  { name: 'Saint Helena, Ascension and Tristan Da Cunha', phoneCode: '+290', code: 'SH', flag: '🇸🇭' },
  { name: 'Saint Kitts and Nevis', phoneCode: '+1869', code: 'KN', flag: '🇰🇳' },
  { name: 'Saint Lucia', phoneCode: '+1758', code: 'LC', flag: '🇱🇨' },
  { name: 'Saint Martin', phoneCode: '+590', code: 'MF', flag: '🇲🇫' },
  { name: 'Saint Pierre and Miquelon', phoneCode: '+508', code: 'PM', flag: '🇵🇲' },
  { name: 'Saint Vincent and the Grenadines', phoneCode: '+1784', code: 'VC', flag: '🇻🇨' },
  { name: 'Samoa', phoneCode: '+685', code: 'WS', flag: '🇼🇸' },
  { name: 'San Marino', phoneCode: '+378', code: 'SM', flag: '🇸🇲' },
  { name: 'Sao Tome and Principe', phoneCode: '+239', code: 'ST', flag: '🇸🇹' },
  { name: 'Saudi Arabia', phoneCode: '+966', code: 'SA', flag: '🇸🇦' },
  { name: 'Senegal', phoneCode: '+221', code: 'SN', flag: '🇸🇳' },
  { name: 'Serbia', phoneCode: '+381', code: 'RS', flag: '🇷🇸' },
  { name: 'Seychelles', phoneCode: '+248', code: 'SC', flag: '🇸🇨' },
  { name: 'Sierra Leone', phoneCode: '+232', code: 'SL', flag: '🇸🇱' },
  { name: 'Singapore', phoneCode: '+65', code: 'SG', flag: '🇸🇬' },
  { name: 'Slovakia', phoneCode: '+421', code: 'SK', flag: '🇸🇰' },
  { name: 'Slovenia', phoneCode: '+386', code: 'SI', flag: '🇸🇮' },
  { name: 'Solomon Islands', phoneCode: '+677', code: 'SB', flag: '🇸🇧' },
  { name: 'Somalia', phoneCode: '+252', code: 'SO', flag: '🇸🇴' },
  { name: 'South Africa', phoneCode: '+27', code: 'ZA', flag: '🇿🇦' },
  { name: 'South Georgia and the South Sandwich Islands', phoneCode: '+500', code: 'GS', flag: '🇬🇸' },
  { name: 'Spain', phoneCode: '+34', code: 'ES', language: 'ES', flag: '🇪🇸' },
  { name: 'Sri Lanka', phoneCode: '+94', code: 'LK', flag: '🇱🇰' },
  { name: 'Sudan', phoneCode: '+249', code: 'SD', flag: '🇸🇩' },
  { name: 'South Sudan', phoneCode: '+211', code: 'SS', flag: '🇸🇸' },
  { name: 'Suriname', phoneCode: '+597', code: 'SR', flag: '🇸🇷' },
  { name: 'Svalbard and Jan Mayen', phoneCode: '+47', code: 'SJ', flag: '🇸🇯' },
  { name: 'Swaziland', phoneCode: '+268', code: 'SZ', flag: '🇸🇿' },
  { name: 'Sweden', phoneCode: '+46', code: 'SE', flag: '🇸🇪' },
  { name: 'Switzerland', phoneCode: '+41', code: 'CH', flag: '🇨🇭' },
  { name: 'Syrian Arab Republic', phoneCode: '+963', code: 'SY', flag: '🇸🇾' },
  { name: 'Taiwan, Province of China', phoneCode: '+886', code: 'TW', flag: '🇹🇼' },
  { name: 'Tajikistan', phoneCode: '+992', code: 'TJ', flag: '🇹🇯' },
  { name: 'Tanzania, United Republic of', phoneCode: '+255', code: 'TZ', flag: '🇹🇿' },
  { name: 'Thailand', phoneCode: '+66', code: 'TH', flag: '🇹🇭' },
  { name: 'Timor-Leste', phoneCode: '+670', code: 'TL', flag: '🇹🇱' },
  { name: 'Togo', phoneCode: '+228', code: 'TG', flag: '🇹🇬' },
  { name: 'Tokelau', phoneCode: '+690', code: 'TK', flag: '🇹🇰' },
  { name: 'Tonga', phoneCode: '+676', code: 'TO', flag: '🇹🇴' },
  { name: 'Trinidad and Tobago', phoneCode: '+1868', code: 'TT', flag: '🇹🇹' },
  { name: 'Tunisia', phoneCode: '+216', code: 'TN', flag: '🇹🇳' },
  { name: 'Turkey', phoneCode: '+90', code: 'TR', flag: '🇹🇷' },
  { name: 'Turkmenistan', phoneCode: '+993', code: 'TM', flag: '🇹🇲' },
  { name: 'Turks and Caicos Islands', phoneCode: '+1649', code: 'TC', flag: '🇹🇨' },
  { name: 'Tuvalu', phoneCode: '+688', code: 'TV', flag: '🇹🇻' },
  { name: 'Uganda', phoneCode: '+256', code: 'UG', flag: '🇺🇬' },
  { name: 'Ukraine', phoneCode: '+380', code: 'UA', flag: '🇺🇦' },
  { name: 'United Arab Emirates', phoneCode: '+971', code: 'AE', flag: '🇦🇪' },
  { name: 'United Kingdom', phoneCode: '+44', code: 'GB', flag: '🇬🇧' },
  { name: 'United States', phoneCode: '+1', code: 'US', language: 'EN', flag: '🇺🇸' },
  { name: 'Uruguay', phoneCode: '+598', code: 'UY', flag: '🇺🇾' },
  { name: 'Uzbekistan', phoneCode: '+998', code: 'UZ', flag: '🇺🇿' },
  { name: 'Vanuatu', phoneCode: '+678', code: 'VU', flag: '🇻🇺' },
  { name: 'Venezuela, Bolivarian Republic of', phoneCode: '+58', code: 'VE', flag: '🇻🇪' },
  { name: 'Viet Nam', phoneCode: '+84', code: 'VN', flag: '🇻🇳' },
  { name: 'Virgin Islands, British', phoneCode: '+1284', code: 'VG', flag: '🇻🇬' },
  { name: 'Virgin Islands, U.S.', phoneCode: '+1340', code: 'VI', flag: '🇻🇮' },
  { name: 'Wallis and Futuna', phoneCode: '+681', code: 'WF', flag: '🇼🇫' },
  { name: 'Yemen', phoneCode: '+967', code: 'YE', flag: '🇾🇪' },
  { name: 'Zambia', phoneCode: '+260', code: 'ZM', flag: '🇿🇲' },
  { name: 'Zimbabwe', phoneCode: '+263', code: 'ZW', flag: '🇿🇼' },
  { name: 'Åland Islands', phoneCode: '+358', code: 'AX', flag: '🇦🇽' }
]

type GetCountriesOptionsProps = {
  withFlag?: boolean
  onlyLanguage?: boolean
  translated?: boolean
  nameIsCode?: boolean
  withPhoneCode?: boolean
  noName?: boolean
  phoneCodeIsValue?: boolean
}

const getCountriesOptions = ({ withFlag, onlyLanguage, translated, nameIsCode, withPhoneCode, noName, phoneCodeIsValue }: GetCountriesOptionsProps): Option[] => {
  return countries.filter((country: Country) => onlyLanguage ? country.language : true).map((country: Country) => {
    let label = noName ? '' : nameIsCode ? country.code : (translated || onlyLanguage) ? i18next.t(onlyLanguage ? `global:languages.${country.language!}` : `global:countries.${country.code}`) : country.name

    if (withFlag) {
      label = `${label} ${country.flag}`
    }

    if (withPhoneCode) {
      label = `${label} ${country.phoneCode} `
    }

    return {
      id: country.code,
      value: phoneCodeIsValue ? country.phoneCode : onlyLanguage ? country.language! : country.code,
      label
    }
  })
}

const getPhoneCode = (countryCode: string): string => {
  const filtered = countries.filter((country: Country) => country.code === countryCode)
  return filtered.length ? filtered[0].phoneCode : ''
}

export { countries, getCountriesOptions, getPhoneCode }
