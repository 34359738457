import global from './global_fr.json'
import login from './login_fr.json'
import register from './register_fr.json'
import reset from './reset_fr.json'
import forgot from './forgot_fr.json'
import inputs from './inputs_fr.json'
import medias from './medias_fr.json'
import integrations from './integrations_fr.json'
import conversations from './conversations_fr.json'
import settings from './settings_fr.json'
import agents from './agents_fr.json'
import welcome from './welcome_fr.json'
import profile from './profile_fr.json'
import menu from './menu_fr.json'
import offers from './offers_fr.json'
import onboarding from './onboarding_fr.json'

export default {
  global,
  login,
  register,
  reset,
  forgot,
  inputs,
  medias,
  integrations,
  conversations,
  settings,
  agents,
  welcome,
  profile,
  menu,
  offers,
  onboarding
}
