import { createContext, useState, useContext, useEffect, useCallback, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import axios from 'axios'

import { onAuthStateChanged, signOut } from 'firebase/auth'

import { useFirebase } from './firebase'

import { User } from 'types'

axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.defaults.headers.post['Content-Type'] = 'application/json'

type UserProviderProps = {
  children: ReactNode
}

 type InitialValues = {
  loading: boolean
  user?: User
  updateUser: (user: User) => void
  logout: () => void
}

const initialValues: InitialValues = {
  loading: true,
  user: undefined,
  updateUser: (user: User) => {},
  logout: () => {}
}

const UserContext = createContext(initialValues)

const UserProvider = ({ children }: UserProviderProps) => {
  const { i18n } = useTranslation()
  const { auth } = useFirebase()
  const [loading, setLoading] = useState<boolean>(true)
  const [user, setUser] = useState<User | undefined>(undefined)

  useEffect(() => {
    const myInterceptor = axios.interceptors.response.use((res) => res, (err) => {
      if (err?.response?.data?.code === 'auth/id-token-expired') {
        location.reload()
      }
      return err
    })
    return () => axios.interceptors.response.eject(myInterceptor)
  }, [])

  const updateUser = useCallback((newUser: any) => {
    setUser((prev: any) => ({
      ...prev,
      ...newUser
    }))
    newUser.language && i18n.changeLanguage(newUser.language.toLowerCase())
  }, [i18n, setUser])

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser && !user) {
        const token = await firebaseUser.getIdToken()
        axios.defaults.headers.common.authorization = token
        const resUser: any = await axios.get('/users/me')
        if (resUser?.response?.data?.code === 'auth/id-token-revoked') {
          logout()
          return
        } else if (!resUser.data.data.language) {
          axios.put('users/me', {
            language: i18n.language.split('-')[0].toUpperCase()
          })
        }
        updateUser(resUser.data.data)
        setLoading(false)
      } else if (!firebaseUser) {
        setUser(undefined)
        axios.defaults.headers.common.authorization = undefined
        setLoading(false)
      }
    })
    return unsubscribe
  }, [])

  const logout = async () => {
    await signOut(auth)
  }

  return (
    <UserContext.Provider value={{
      loading,
      user,
      updateUser,
      logout
    }}>
      {children}
    </UserContext.Provider>
  )
}

const useUser = () => {
  return useContext(UserContext)
}

export { UserProvider, useUser }
