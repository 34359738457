import { Fragment, ReactElement } from 'react'

import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

type SliderProps = {
  title: string
  description?: string | null
  children: ReactElement
  footer?: ReactElement
  open: boolean
  onClose: () => void
}

const Slider = ({ title, description, children, footer, open, onClose }: SliderProps) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <div className="fixed inset-0 backdrop-blur-[1px]" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-2xl shadow-gray-400">
                    <div className="flex-1">
                      {/* Header */}
                      <div className="bg-gray-50 px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between space-x-3">
                          <div className="space-y-1">
                            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">{title}</Dialog.Title>
                            {description ? <p className="text-sm text-gray-500">{description}</p> : null}
                          </div>
                          <div className="flex h-7 items-center">
                            <button
                              type="button"
                              className="text-gray-400 hover:text-gray-500"
                              onClick={onClose}
                            >
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* Divider container */}
                      <div className="sm:divide-y sm:divide-gray-200">
                        <div className="px-4 sm:px-6">
                          <div className="sm:py-3">
                            {children}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Action buttons */}
                    {footer
                      ? <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6 flex flex-row-reverse justify-between">
                          {footer}
                        </div>
                      : null
                    }
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default Slider
