import { Link } from 'react-router-dom'

// eslint-disable-next-line import/no-duplicates
import logoLong from 'assets/images/logos/main.png'// main-long.png
// eslint-disable-next-line import/no-duplicates
import logo from 'assets/images/logos/main.png'

import { twMerge } from 'tailwind-merge'

type LogoProps = {
  type?: 'LONG' | 'SQUARE'
  to?: string | null
  className?: string
}

const Logo = ({ type = 'LONG', to = '/', className = '' }: LogoProps) => {
  return <div className="w-fit">
    {to
      ? <Link to={to}>
          <img className={twMerge('h-12 w-auto', className)} src={type === 'LONG' ? logoLong : logo} alt="BeChat" />
        </Link>
      : <img className={twMerge('h-12 w-auto', className)} src={type === 'LONG' ? logoLong : logo} alt="BeChat" />
    }
  </div>
}

export default Logo
