import { useMemo, Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { ChevronLeftIcon, ChevronRightIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/20/solid'

import { twMerge } from 'tailwind-merge'

type PaginationProps = {
  countData: number
  perPage: number
  page: number
  setPage: Dispatch<SetStateAction<number>>
  setPerPage: Dispatch<SetStateAction<number>>
  className?: string
}

const Pagination = ({ countData, perPage, page, setPage, setPerPage, className }: PaginationProps) => {
  const { t } = useTranslation()

  const totalPage = useMemo(() => Math.ceil(countData / perPage), [perPage, countData])

  return (
    <div className={twMerge('flex items-center justify-between bg-white py-3', className)}>
      {totalPage > 1
        ? <div className="flex flex-1 justify-between sm:hidden">
            <a
              href="#"
              className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              {t('global:pagination.previous')}
            </a>
            <a
              href="#"
              className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              {t('global:pagination.next')}
            </a>
          </div>
        : null
      }
      <div className="hidden sm:flex sm:flex-1 sm:items-start sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            <span className="font-medium">{countData}</span> {t('global:pagination.results')}
          </p>
        </div>
        {totalPage > 1
          ? <div>
              <nav className="isolate inline-flex rounded-md shadow-sm" aria-label="Pagination">
                <a
                  className={twMerge('relative inline-flex items-center px-2 py-2 rounded-l-md text-gray-400 border border-gray-300 border-r-0', page === 1 ? '' : 'cursor-pointer hover:bg-gray-50')}
                  onClick={page === 1 ? undefined : () => setPage(1)}
                >
                  <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
                </a>
                <a
                  className={twMerge('relative inline-flex items-center px-2 py-2 text-gray-400 border border-gray-300', page === 1 ? '' : 'cursor-pointer hover:bg-gray-50')}
                  onClick={page === 1 ? undefined : () => setPage(prev => prev - 1)}
                >
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </a>
                {Array(totalPage).fill(0).map((_v, i: number) => (page - 2 - (totalPage - page < 2 ? 7 - (totalPage - i) : 0) <= (i + 1) && page + 2 + (page < 3 ? 6 - i : 0) >= (i + 1))
                  ? <a
                      key={i}
                      onClick={i + 1 === page ? undefined : () => setPage(i + 1)}
                      className={twMerge('border-l-0 select-none relative border inline-flex items-center px-4 py-2 text-sm', i + 1 === page ? 'text-white bg-primary font-semibold border-gray-300 border-y-primary' : 'border-gray-300 hover:bg-gray-50 text-gray-700 cursor-pointer')}
                    >
                      {i + 1}
                    </a>
                  : null
                )}
                <a
                  onClick={page === totalPage ? undefined : () => setPage(prev => prev + 1)}
                  className={twMerge('relative inline-flex items-center px-2 py-2 text-gray-400 border border-l-0 border-gray-300', page === totalPage ? '' : 'cursor-pointer hover:bg-gray-50')}
                >
                  <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </a>
                <a
                  onClick={page === totalPage ? undefined : () => setPage(totalPage)}
                  className={twMerge('relative inline-flex items-center px-2 py-2 text-gray-400 rounded-r-md border border-l-0 border-gray-300', page === totalPage ? '' : 'cursor-pointer hover:bg-gray-50')}
                >
                  <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
                </a>
              </nav>
            </div>
          : null
        }
      </div>
    </div>
  )
}

export default Pagination
