import { ReactNode, LegacyRef } from 'react'
import { useFormContext } from 'react-hook-form'

import Spinner from '../spinner'
import { twMerge } from 'tailwind-merge'

type SubmitProps = {
  children: ReactNode
  outlined?: boolean
  className?: string
  submitRef?: LegacyRef<HTMLButtonElement>
}

const Submit = ({ children, outlined = false, className = '', submitRef }: SubmitProps) => {
  const { formState: { isSubmitting, isDirty } } = useFormContext()

  return (
    <button
      type="submit"
      ref={submitRef}
      disabled={isSubmitting || !isDirty}
      className={outlined
        ? twMerge('rounded-md bg-white font-medium text-primary-dark focus:outline-none', isDirty ? 'hover:text-primary' : 'text-gray-400', className)
        : twMerge('flex justify-center rounded-md border py-2 px-4 text-sm font-medium shadow-sm bg-primary text-white border-transparent focus:outline-none focus:ring-2 focus:ring-primary-light focus:ring-offset-2', isDirty ? 'hover:bg-primary-dark' : '', className)
      }
    >
      {isSubmitting
        ? <Spinner size={5} />
        : children
      }
    </button>
  )
}

export default Submit
