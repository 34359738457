import { useState, useEffect } from 'react'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'

import { twMerge } from 'tailwind-merge'

type SearchProps = {
  value: string
  setValue: (newValue: string) => void
  readOnly?: boolean
  placeholder?: string
  className?: string
  bufferTime?: number
}

const Search = ({ value, setValue, readOnly = false, placeholder, className = '', bufferTime = 1000 }: SearchProps) => {
  const [temporedValue, setTemporedValue] = useState<string>(value)

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setValue(temporedValue)
    }, bufferTime)

    return () => clearTimeout(delayDebounceFn) // tried with  useDeferredValue but was automatically updating the deferred value, don't know why
  }, [temporedValue])

  return (
    <div className={twMerge('w-full relative', className)}>
      <div className="relative w-full">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          value={temporedValue}
          onChange={(e) => setTemporedValue(e.target.value)}
          className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 pl-10 placeholder-gray-400 shadow-sm focus:border-primary-light focus:outline-none focus:ring-primary-light sm:text-sm'
          readOnly={readOnly}
          placeholder={placeholder}
        />
      </div>
    </div>
  )
}

export default Search
