import { useState, Dispatch, useMemo, SetStateAction } from 'react'
import { Intervals } from 'types'

import { useTranslation } from 'react-i18next'
import { getOffers } from 'utils/datas/offers'
import { Button } from 'components/buttons'
import { Switch } from '@headlessui/react'
import { twMerge } from 'tailwind-merge'

import { useWorkspace } from 'contexts'
import axios from 'axios'

type SwitchIntervalProps = {
  view: Intervals
  setView: Dispatch<SetStateAction<Intervals>>
}

const SwitchInterval = ({ view, setView }: SwitchIntervalProps) => {
  const { t } = useTranslation()

  return (
    <Switch.Group as="div" className="flex items-center">
      <Switch
        checked={view === 'YEAR'}
        onChange={(v: boolean) => setView(v ? 'YEAR' : 'MONTH')}
        className={twMerge(
          view === 'YEAR' ? 'bg-primary' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2'
        )}
      >
        <span
          aria-hidden="true"
          className={twMerge(
            view === 'YEAR' ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </Switch>
      <Switch.Label as="span" className="ml-3 text-sm">
        <span className="font-medium text-gray-900">{t('offers:switch.title')}</span>
        <span className="text-gray-500 ml-1">{t('offers:switch.gift')}</span>
      </Switch.Label>
    </Switch.Group>
  )
}

const planTypeValue = {
  STARTER: 0,
  BUSINESS: 1,
  ENTERPRISE: 2
}

type OffersProps = {
  classNameContainer?: string
}

const Offers = ({ classNameContainer }: OffersProps) => {
  const { t } = useTranslation()
  const { selectedWorkspace } = useWorkspace()

  const [view, setView] = useState<Intervals>('YEAR')
  const offers = useMemo(() => getOffers(t), [t])

  const openIt = async (price: string) => {
    const res = await axios.get(`/${selectedWorkspace?.id}/tools/stripe/session?price=${price}&currentPath=${location.pathname}`)
    window.location.href = res.data.data
  }

  return <div className={twMerge('flex flex-col gap-8', classNameContainer)}>
    <div className='flex items-center justify-center'>
      <SwitchInterval view={view} setView={setView} />
    </div>
    <div className='flex gap-4 w-full justify-center'>
      {!offers.length
        ? <div className="border-t-transparent border-solid animate-spin rounded-full border-primary border-8 h-48 w-48" />
        : offers.map(offer => <div key={`offer-card-${offer.id}`} className={twMerge('bg-white p-4 pt-6 relative flex flex-col w-1/3 items-center justify-between gap-4 rounded-md shadow-md border', offer.type === selectedWorkspace?.plan?.type ? 'shadow-primary border-primary' : 'shadow-gray-300 border-gray-300')}>
            {offer.favorite
              ? <div className='absolute right-2 top-2 text-xs bg-primary text-white rounded-full px-2 py-1'>{t('offers:favorite')}</div>
              : null
            }
            <div className='flex flex-col items-center'>
              <div className='text-xl font-semibold'>
                {t(`offers:type.${offer.type}`)}
              </div>
              <div className='text-base flex flex-col items-center'>
                <span>{Math.ceil(offer.prices.find(p => p.interval === view)?.pricePerMonth || 0)}{t(`offers:currency.${offer.prices.find(p => p.interval === view)?.currency}`)} / {t('offers:interval.MONTH')}</span>
                <span className='text-2xs -mt-2'>{t('offers:vat')}</span>
              </div>
              <ul className='mt-4 flex flex-col gap-2'>
                {offer.features.map(feature => <li key={`${offer.id}-feature-${feature}`}>{feature}</li>)}
              </ul>
            </div>
            {offer.type === selectedWorkspace?.plan?.type
              ? <div className='flex flex-col items-center'>
                  <span className='text-lg font-medium text-primary'>{t('offers:actions.current')}</span>
                  {/* {view === selectedWorkspace?.plan?.interval || selectedWorkspace?.plan?.type === 'STARTER' ? null : <span className='text-2xs font-light text-gray-800 -mt-2'>{t(`offers:with.${selectedWorkspace?.plan?.interval}`)}</span>} */}
                </div>
              : <Button className='mt-4' onClick={() => openIt(offer.prices.find(p => p.interval === view)?.stripeId!)} outlined={planTypeValue[offer.type] < planTypeValue[selectedWorkspace!.plan.type]}>
                  {planTypeValue[offer.type] < planTypeValue[selectedWorkspace!.plan.type] ? t('offers:actions.downgrade')! : t('offers:actions.upgrade')!}
                </Button>
            }
          </div>
        )
      }
    </div>
  </div>
}

export default Offers
