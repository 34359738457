import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'

import FilePreview from '../filePreview'

type FileProps = {
  id: string
  image?: boolean
  imagePlaceholder?: 'user' | 'company',
  readOnly?: boolean
  info?: string
  displayPlaceholderIfNoValue?: boolean
}

const File = ({ id, image, imagePlaceholder, readOnly, info, displayPlaceholderIfNoValue }: FileProps) => {
  const methods = useFormContext()
  const { t } = useTranslation()

  const { watch, register, setValue, formState: { errors } } = methods

  const removeFile = () => {
    setValue(id, 'REMOVE', { shouldDirty: true })
  }

  return (
    <div className="w-full relative flex items-center">
      <div className="w-full relative flex items-center ml-2">
        <span className={`${image ? 'ml-1' : 'ml-3'}`}>
          <FilePreview file={watch(id) === 'REMOVE' ? null : watch(id)} type={imagePlaceholder} onClick={() => document.getElementById(`file-input-file-${id}`)?.click()} readOnly={readOnly} displayPlaceholderIfNoValue={displayPlaceholderIfNoValue} />
          <input id={`file-input-file-${id}`} {...register(id)} type='file' accept={image ? 'image/*' : '*'} className='hidden' />
        </span>
        {!readOnly
          ? <span className="ml-4 flex flex-shrink-0 items-center space-x-4">
              {watch(id) !== 'REMOVE' && watch(id)?.length
                ? <button
                    type='button'
                    onClick={removeFile}
                    className="rounded-md bg-white font-medium text-primary-dark hover:text-primary focus:outline-none"
                  >
                    {t('global:actions.delete')}
                  </button>
                : <button
                    type='button'
                    onClick={() => document.getElementById(`file-input-file-${id}`)?.click()}
                    className="rounded-md bg-white font-medium text-primary-dark hover:text-primary focus:outline-none"
                  >
                    {t('global:actions.upload')}
                  </button>
              }
            </span>
          : null
        }
      </div>
      {(errors as any)?.[id]
        ? <p className="mt-3 text-sm text-red-600 pl-4" id={`${id}-error`}>
            {(errors as any)?.[id]?.message}
          </p>
        : info
          ? <p className="mt-3 text-sm text-gray-500 pl-4" id={`${id}-info`}>
              {info}
            </p>
          : null
      }
    </div>
  )
}

export default File
