import { ReactNode } from 'react'

import { useFirebase, FirebaseProvider } from './firebase'
import { useUser, UserProvider } from './user'
import { useWorkspace, WorkspaceProvider } from './workspace'
import { useNotifications, NotificationsProvider } from './notifications'

export { useFirebase, useUser, useWorkspace, useNotifications }

type ContextsProviderProps = {
  children: ReactNode
}

const ContextsProvider = ({ children } : ContextsProviderProps) => {
  return (
    <NotificationsProvider>
      <FirebaseProvider>
        <UserProvider>
          <WorkspaceProvider>
            {children}
          </WorkspaceProvider>
        </UserProvider>
      </FirebaseProvider>
    </NotificationsProvider>
  )
}

export default ContextsProvider
