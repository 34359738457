import { Fragment, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, Controller, FieldValues } from 'react-hook-form'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon, ExclamationCircleIcon, XCircleIcon } from '@heroicons/react/20/solid'

import { twMerge } from 'tailwind-merge'

import type { Option } from 'types'

type SelectInputProps = {
  label?: string
  placeholder?: string
  disabled?: boolean
  readOnly?: boolean
  options: Option[]
  error?: any
  reset?: any
  multiple?: boolean
}

const SelectInput = ({ value, onChange, label, placeholder, readOnly, disabled, options, error, myReset, multiple }: SelectInputProps & FieldValues) => {
  const { t } = useTranslation()

  const valueLabel: string | null = useMemo(() => {
    const selectedOption = options.filter(option => multiple ? value.includes(option.value) : option.value === value).map(option => option.label)
    return selectedOption.length ? selectedOption.join(', ') : null
  }, [value, options])

  if (readOnly || disabled) {
    return (
      <span className="h-10 flex items-center ml-3">{valueLabel || ''}</span>
    )
  }

  return (
    <div className='w-full'>
      <Listbox value={value} onChange={onChange} disabled={readOnly || disabled} multiple={multiple}>
        {({ open }) => (
          <div className='w-full'>
            {label
              ? <Listbox.Label className="block text-sm font-normal text-custom-gray4 mb-1">{label}</Listbox.Label>
              : null
            }
            <div className="relative">
              <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm">
                <span className={twMerge('block truncate', valueLabel ? '' : 'text-gray-400')}>{valueLabel || placeholder || t('global.select.placeholder')}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-primary ring-opacity-5 focus:outline-none sm:text-sm">
                  {options.map((option: Option) => (
                    <Listbox.Option
                      key={option.value}
                      className={({ active }) =>
                        twMerge(
                          active ? 'text-white bg-primary' : 'text-gray-900',
                          'relative cursor-pointer select-none py-2 pl-3 pr-9'
                        )
                      }
                      value={option.value}
                    >
                      {({ selected, active }) => (
                        <>
                          <span className={twMerge('block truncate', selected ? 'font-semibold' : 'font-normal')}>
                            {option.label}
                          </span>

                          {selected
                            ? (
                                <span
                                  className={twMerge(
                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                    active ? 'text-white' : 'text-primary'
                                  )}
                                >
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              )
                            : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>

              {error
                ? <div className="pointer-events-none absolute inset-y-0 right-14 flex items-center">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                  </div>
                : null
              }
              {myReset && value?.length && !readOnly && !disabled
                ? <div className="absolute inset-y-0 right-8 flex items-center cursor-pointer" onClick={myReset}>
                    <XCircleIcon className="h-5 w-5 text-gray-300" aria-hidden="true" />
                  </div>
                : null
              }
            </div>
          </div>
        )}
      </Listbox>

      {error
        ? <p className="mt-2 text-sm text-red-600">
            {error.message}
          </p>
        : null
      }
    </div>
  )
}

type SelectProps = {
  id: string
  label?: string
  placeholder?: string
  disabled?: boolean
  readOnly?: boolean
  options: Option[]
  disableReset?: boolean
  multiple?: boolean
}

const Select = ({ id, label, placeholder, readOnly, disabled, options, disableReset, multiple }: SelectProps) => {
  const { control, formState: { errors }, resetField } = useFormContext()

  return (
    <Controller
      render={({ field }) => <SelectInput {...field} multiple={multiple} myReset={!disableReset ? () => resetField(id) : undefined} label={label} placeholder={placeholder} readOnly={readOnly} disabled={disabled} options={options} error={errors[id]} /> }
      name={id}
      control={control}
      defaultValue={multiple ? [] : undefined}
    />
  )
}

export { SelectInput as IsolatedSelect }

export default Select
