import global from './global_en.json'
import login from './login_en.json'
import register from './register_en.json'
import reset from './reset_en.json'
import forgot from './forgot_en.json'
import inputs from './inputs_en.json'
import medias from './medias_en.json'
import integrations from './integrations_en.json'
import conversations from './conversations_en.json'
import settings from './settings_en.json'
import agents from './agents_en.json'
import welcome from './welcome_en.json'
import profile from './profile_en.json'
import menu from './menu_en.json'
import offers from './offers_en.json'
import onboarding from './onboarding_en.json'

export default {
  global,
  login,
  register,
  reset,
  forgot,
  inputs,
  medias,
  integrations,
  conversations,
  settings,
  agents,
  welcome,

  profile,
  menu,
  offers,
  onboarding
}
