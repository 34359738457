import { Fragment, ReactNode } from 'react'
import { useFormContext, Controller, FieldValues } from 'react-hook-form'

import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon, XCircleIcon } from '@heroicons/react/20/solid'
import { twMerge } from 'tailwind-merge'

type CustomListboxProps = {
  label?: string
  placeholder?: string
  className?: string
  options: any[]
  reset?: () => void
  displayOption: (data: any) => ReactNode
}

const CustomListbox = ({ value, onChange, label, placeholder, options, displayOption, className, reset }: CustomListboxProps & FieldValues) => {
  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <>
          {label
            ? <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900 mb-2">{label}</Listbox.Label>
            : null
          }
          <div className={'relative'}>
            <Listbox.Button className={twMerge('relative w-full cursor-pointer rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm border border-gray-300 focus:outline-none focus:border-2 focus:border-primary sm:text-sm sm:leading-6', className)}>
              <span className={twMerge('flex items-center', value ? '' : 'text-gray-400')}>
                {value ? displayOption(value) : <span>{placeholder || label || 'placeholder'}</span>}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options.map((option: any) => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) =>
                      twMerge(
                        active ? 'bg-primary text-white' : 'text-gray-900',
                        'relative cursor-pointer select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={option}
                  >

                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          {displayOption(option)}
                        </div>

                        {selected
                          ? <span
                              className={twMerge(
                                active ? 'text-white' : 'text-primary',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          : null
                        }
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
            {reset && value
              ? <div className="absolute inset-y-0 right-8 flex items-center cursor-pointer" onClick={reset}>
                  <XCircleIcon className="h-5 w-5 text-gray-300" aria-hidden="true" />
                </div>
              : null
            }
          </div>
        </>
      )}
    </Listbox>
  )
}

type ListboxControllerProps = {
  id: string
  canReset?: boolean
}

const ListboxController = ({ id, label, placeholder, options, displayOption, className, canReset }: ListboxControllerProps & CustomListboxProps) => {
  const { control, setValue } = useFormContext()

  return (
    <Controller
      render={({ field }) => <CustomListbox {...field} label={label} placeholder={placeholder} options={options} displayOption={displayOption} className={className} reset={canReset ? () => setValue(id, undefined, { shouldDirty: true }) : undefined} /> }
      name={id}
      control={control}
      defaultValue={undefined}
    />
  )
}

export { ListboxController, CustomListbox as Listbox }

export default CustomListbox
