import { Fragment, useRef, useState, Children, ReactElement, isValidElement, cloneElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { Dialog, Transition } from '@headlessui/react'
import { PencilIcon } from '@heroicons/react/24/outline'

import { useTranslation } from 'react-i18next'

import { useNotifications } from 'contexts'
import axios from 'axios'

type EditProps = {
  children?: ReactElement
  url: 'constructionSites' | 'users' | 'vehicles' | 'companies' | 'invoices' | 'driverLicenses' | 'jobs' | 'invoiceLabels' | 'files'
  id: string
  redirectUrl?: string
  name?: string
  noIcon?: boolean
  type?: 'FILLED' | 'OUTLINED' | 'WORDED'
  confirmAction?: (res: any) => void
  fullyCustom?: boolean
  defaultValue?: string
}

const Edit = ({ url, id, redirectUrl, name, noIcon, type = 'FILLED', children, confirmAction, fullyCustom, defaultValue = '' }: EditProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { openNotification } = useNotifications()
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState<string>(defaultValue)

  const cancelButtonRef = useRef(null)

  const confirm = () => {
    axios.put(`/${url}/${id}`, {
      name: value
    })
      .then((res) => {
        setOpen(false)
        openNotification({ status: 'SUCCESS', title: t('actions.edit.success') })
        if (confirmAction) {
          confirmAction(res)
        }
        if (redirectUrl) {
          navigate(redirectUrl)
        }
      })
  }

  return (
    <>
      {fullyCustom && children
        ? Children.map(children, (child: ReactElement<{onClick: () => void}>) => isValidElement(child) ? cloneElement(child, { onClick: () => setOpen(true) }) : child)
        : <button
            className={type === 'FILLED'
              ? 'flex justify-center items-center rounded-md border py-2 px-4 text-sm font-medium shadow-sm bg-blue-600 text-white border-transparent hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
              : type === 'OUTLINED'
                ? 'rounded-md bg-white font-medium text-primary-dark hover:text-primary focus:outline-none'
                : type === 'WORDED'
                  ? 'text-blue-600 hover:text-blue-500 select-none flex justify-center items-center'
                  : ''
            }
            onClick={() => setOpen(true)}
          >
            {children || <>
              {!noIcon ? <PencilIcon className='h-4 w-4 mr-2' /> : null}
              {t('actions.edit')}
            </>}
          </button>
      }
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                      <PencilIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        {t('dialogs.edit.title')} {name}
                      </Dialog.Title>
                      <div className="mt-2">
                        <input className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary-light focus:outline-none focus:ring-primary-light sm:text-sm' value={value} onKeyUp={(e) => { if (e.key === 'Enter') { e.preventDefault(); confirm() } }} onChange={e => setValue(e.target.value)} placeholder={t('name.label')!} />
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                      onClick={confirm}
                    >
                      {t('actions.confirm')}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      {t('actions.cancel')}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default Edit
