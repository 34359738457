import { ReactNode, LegacyRef } from 'react'
import { FormProvider, UseFormReturn } from 'react-hook-form'

import { Submit } from './buttons'
import { twMerge } from 'tailwind-merge'

type FormProps = {
  children: ReactNode
  description?: string
  submitButton?: string
  submit?: (data: any) => Promise<void>
  methods?: UseFormReturn<any>
  submitRef?: LegacyRef<HTMLButtonElement>
  hidden?: boolean
  autoComplete?: string | undefined
}

const Form = (props: FormProps) => {
  return props.submit && props.methods
    ? <FormProvider {...props.methods}>
        <form onSubmit={props.methods.handleSubmit(props.submit)} className={props.hidden ? 'hidden' : ''} autoComplete={props.autoComplete}>
          <FormContent {...props} />
        </form>
      </FormProvider>
    : <FormContent {...props} />
}

const FormContent = ({ children, description, submitButton, submitRef }: FormProps) => {
  return (
    <div className="flex flex-col gap-4">
      {description
        ? <p className="max-w-2xl text-sm text-gray-500">
            {description}
          </p>
        : null
      }
      <dl className={twMerge('sm:divide-y sm:divide-gray-200')}>
        {children}
      </dl>

      {submitButton || submitRef
        ? <div className={twMerge('flex justify-end', submitRef && !submitButton ? 'hidden' : '')}>
            <Submit submitRef={submitRef}>
              {submitButton}
            </Submit>
          </div>
        : null
      }
    </div>
  )
}

export default Form
