import global from './global_es.json'
import login from './login_es.json'
import register from './register_es.json'
import reset from './reset_es.json'
import forgot from './forgot_es.json'
import inputs from './inputs_es.json'
import medias from './medias_es.json'
import integrations from './integrations_es.json'
import conversations from './conversations_es.json'
import settings from './settings_es.json'
import agents from './agents_es.json'
import welcome from './welcome_es.json'
import profile from './profile_es.json'
import menu from './menu_es.json'
import offers from './offers_es.json'
import onboarding from './onboarding_es.json'

export default {
  global,
  login,
  register,
  reset,
  forgot,
  inputs,
  medias,
  integrations,
  conversations,
  settings,
  agents,
  welcome,
  profile,
  menu,
  offers,
  onboarding
}
